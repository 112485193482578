import React, { useContext } from "react";
import HolidaysContext from "../contexts/holidays-context";
import PersonDates from "./PersonDates";

function HolidayTable({ selectedUser, setSelectedUser }) {
  const context = useContext(HolidaysContext);
  const { translators, user } = context;

  return (
    <div className="holidayTable">
      {translators.map((person, index) => {
        // Checks if the user can control this person
        const canControl =
          // selectedUser === person?.employeeID ||
          parseInt(user.id) === parseInt(person.id) ||
          parseInt(user.type) === 0;
        const canControlSingle = parseInt(user.type) !== 0;

        if (person.id) {
          return (
            <div
              key={index}
              className={
                "holidayRow" +
                (canControl ? " enabled" : " disabled") +
                (canControl && canControlSingle ? " single" : "")
              }
            >
              <div className="person">
                <div
                  style={{
                    background:
                      selectedUser === person?.employeeID ? "#b0cad6" : "",
                  }}
                >
                  <span
                    onClick={() => {
                      if (user?.employeeID == 72) {
                        setSelectedUser(person.employeeID);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                    className="personName"
                    title={person.sum}
                  >
                    {/*person["First Name"]} {person["Last Name"].split(/-/).map(i => i[0]).join("-")*/}
                    {person["First Name"]} {person["Last Name"]}
                  </span>
                </div>
              </div>

              <div className="dates">
                <PersonDates
                  key={person.id}
                  heading={false}
                  canControl={canControl}
                  person={person}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div className="holidayMidHeading holidayHeaderRow">
              <div className="midHeadingInner">{person}</div>
              <div className="dates">
                <PersonDates
                  key={person.id}
                  heading={true}
                  canControl={canControl}
                  person={person}
                />
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default HolidayTable;
