import { useState } from "react";
import { useGeneric } from "../../../useGeneric";

export default function QuickOrderFind({ query,setQuery, orderID, setOrderID }) {

  const text = query?.match(/([a-zA-Z ]+)/)?.[0]?.replace(/^ /,"");
  const number = query?.match(/([\d\.]+)/)?.[0];

  const n = number?.replace(/(^\d+)\..*/, "$1");
  const m = number?.match(/\..*/)
    ? number?.replace(/^\d+\.(\d+)/, "$1")?.replace(/(^\d+)\..*/, "$1")
    : 0;
  const y = number?.match(/\.[^\.]+\..*/)
    ? number
        ?.replace(/^\d+\.(\d+)/, "$1")
        // ?.replace(/^0(\d)/, "$1") : 0;
        ?.replace(/^\d+\.(\d+)/, "$1")
    : 0;

  const { loading, rows } = useGeneric({
    name: query && "getOrdersByNumber",
    query: {
      number: n,
      month: m,
      year: y,
      customer: text || ''
    },
    update: query,
  });

  const orders = !loading && rows?.length ? rows : undefined;

  return (
    <div className="quick-order-find">
      <div>
        <input value={query} placeholder={"numer zlecenia lub klient"}onChange={(e) => setQuery(e.target.value)} />
        <div className="quick-order-results">
          {orders?.map((o) => (
            <div onClick={() => setOrderID(o?.id)}>
              {o.number_order?.replace(/\//g,".")}.{o?.language_main} {o.customers_name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
