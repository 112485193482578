import { useContext, useState } from "react";
import { useGeneric } from "../useGeneric";
import { howMuchHolidayLeft, inflect, objectOrJSONparse } from "../functions";
import AppContext from "../contexts/app-context";
import { useParams } from "react-router-dom";

export default function HolidayYear({ loggedIn, user }) {
  const params = useParams();
  const selectedUser = parseInt(params?.user);
  const c = useContext(AppContext);
  const employeeID = selectedUser || c?.employeeID;

  // alert(params?.user);
  //   const employeeID = user?.employeeID;
  const [year, setYear] = useState(new Date().getFullYear());

  const data = useGeneric({
    name: employeeID && "daysOffYear",
    query: {
      year: year,
      employeeID: employeeID, //76
    },
  });

  const dates = !data?.loading && data?.rows;

  const holidaysCount = useGeneric({
    name: "holidayCount",
    reload: 60,
  });

  const employee = !holidaysCount?.loading && holidaysCount?.rows?.find((i) => i?.id === employeeID);
  const employeeName = employee && (employee?.first_name + " " + employee?.last_name);


  const { hCount, hCountOff, hCountSugg, hCountOffSugg, checkMax } =
    howMuchHolidayLeft(holidaysCount, employeeID);

  const months = [[], [], [], [], [], [], [], [], [], [], [], []];

  if (dates) {
    dates?.forEach((i) => {
      const month = new Date(i?.date)?.getMonth();
      months?.[month]?.push(i);
    });
    // months?.map(m => {
    //     const groups = [];
    //     m?.forEach((d, index, month) => {
    //         const thisDate = new Date(d);
    //         const nextDate = month[index+1] && new Date(month[index+1]);

    //     })
    // })
  }

  const holidayCountYear =
    dates && dates.filter((day) => [1, 2]?.includes(day?.type))?.length;

  const officialCount =
    dates &&
    dates.filter(
      (day) => [1, 2]?.includes(day?.type) && day?.sources?.match(/happ/)
    )?.length;
  const unofficialCount =
    dates &&
    dates.filter(
      (day) => [1, 2]?.includes(day?.type) && day?.sources === "sys2"
    )?.length;

  const dayInflect = ["dni", "dzień", "dni", "dni"];

  return (
    <div className="fullScreen holiday-year" style={{ padding: "16px" }}>
      <div>{employeeName}</div>
      <div className="holiday-stats-main">
        <div className="heading">Urlop wypoczynkowy</div>
        <div className="contents">
          <div>
            <div style={{ fontSize: "10px" }}>PULA ROCZNA: </div>
            <div>{inflect(hCountOff + officialCount, dayInflect)}</div>
          </div>
          <div>
            <div style={{ fontSize: "10px" }}>Dostępnych:</div>
            <div>{hCountOffSugg}</div>
          </div>
        </div>
        <div className="heading">
          Zatwierdzone {inflect(officialCount, dayInflect)}
        </div>
        <div className="heading">Planowane: {unofficialCount}</div>
      </div>
      <div className="year-date-grid">
        {months.map((m, index) => {
          const holidayCount = m.filter((day) =>
            [1, 2]?.includes(day?.type)
          )?.length;
          return (
            <div className="year-date-month">
              <div className="month-name">
                <span style={{ fontWeight: "bold" }}>
                  {new Date(2000, index, 15)
                    ?.toLocaleDateString("pl-PL", {
                      month: "long",
                    })
                    ?.toUpperCase()}
                </span>{" "}
                <span className="holiday-count">
                  {holidayCount ? (
                    <>({inflect(holidayCount, dayInflect)})</>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              {m && m?.length ? (
                m.map((i) => (
                  <div
                    className={
                      "year-date-unit " +
                      i?.classname +
                      (i?.sources.match(/happ/) ? " official" : "")
                    }
                  >
                    {i?.sources.match(/happ/) ? (
                      <></>
                    ) : (
                      <div className="planned">planowany</div>
                    )}
                    <div style={{ fontWeight: "bold" }}>
                      {new Date(i?.date).toLocaleDateString()}
                    </div>
                    <div>{i?.typestring}</div>
                  </div>
                ))
              ) : (
                <>-</>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
