import React, { useState, createContext, useRef } from "react";
import { Link } from "react-router-dom";
import dragon from "../../images/icons8-european-dragon-100.png";
import PersonDates from "../holidays/PersonDates";
import useHolidays from "../holidays/useHolidays";
import HolidaysContext from "../contexts/holidays-context";
import { URL } from "../global";
import HolidayTable from "../holidays/HolidayTable";
import HolidayCalendar from "../holidays/HolidayCalendar";
import Checkbox from "../Checkbox";
import HolidayRequests from "./HolidayRequests";
import { useGeneric } from "../useGeneric";
import homeOfficeIcon from "../../images/icons/home.svg";
import Loading from "./Loading";
import { howMuchHolidayLeft } from "../functions";

function Holidays(props) {
  const { user } = props;
  const [selectedUser, setSelectedUser] = useState(false);
  const [date, setDate] = useState(new Date());
  const [change, setChange] = useState(false);
  const [contextParams, setContextParams] = useState();
  const [view, setView] = useState(false);
  const [activeDates, setActiveDates] = useHolidays(
    change + date.getFullYear(),
    date
  );
  const [requests, setRequests] = useState(false);
  const activeTypes = Array(...new Set(activeDates.map((item) => item.type)));
  // console.log('at',activeTypes);
  const today = new Date();
  const months = [];
  const iterateDate = new Date();
  const contextDialog = useRef();

  const holidaysCount = useGeneric({
    name: "holidayCount",
    update: change,
    reload: 15,
  });

  const arW = useGeneric({
    name: "remoteDays",
    // update: change,
    reload: 320,
    query: {
      year: date?.getFullYear(),
    },
  });

  const actualRemoteWork = !arW?.loading && arW?.rows;

  // ile mi zostało urlopu
  // const hCount =
  //   holidaysCount &&
  //   !holidaysCount.loading &&
  //   holidaysCount.rows &&
  //   holidaysCount.rows.length
  //     ? holidaysCount.rows.filter((h) => h.id === user.employeeID)[0]
  //     : undefined;
  // const hCountOff = hCount ? hCount.remaining_holiday : 0;
  // const hCountSugg = hCount ? hCount.suggested_count : 0;
  // const checkMax = hCountOff - hCountSugg;
  // const hCountOffSugg = hCount ? hCount.remaining_holiday_with_suggestions : 0;
  // console.log(checkMax);
  const { hCount, hCountOff, hCountSugg, hCountOffSugg, checkMax } =
    howMuchHolidayLeft(holidaysCount, selectedUser || user?.employeeID);

  iterateDate.setFullYear(date.getFullYear());
  for (let i = 0; i < 12; i++) {
    iterateDate.setMonth(i, 2);
    iterateDate.setHours(0, 0, 0, 0);
    const newDate = new Date(iterateDate);
    months.push({
      date: newDate,
      monthName: newDate.toLocaleDateString("pl-PL", { month: "long" }),
      monthID: newDate.getMonth(),
    });
  }
  console.log(months, iterateDate);
  const dayOffTypes = [
    { className: "uw", fullName: "urlop wypoczynkowy", code: 1 },
    { className: "dzds", fullName: "dzień wolny za święto", code: 6 },
    { className: "zch", fullName: "zwolnienie lekarskie", code: 7 },
    { className: "uok", fullName: "urlop okolicznościowy", code: 3 },
    { className: "dwzn", fullName: "wolne z nadgodzin", code: 9 },
    { className: "dod", fullName: "opieka nad dzieckiem", code: 5 },
    { className: "uz", fullName: "urlop na żądanie", code: 2 },
    { className: "ub", fullName: "urlop bezpłatny", code: 4 },
    { className: "no", fullName: "nieobecność", code: 8 },
    { className: "zdal", fullName: "praca zdalna", code: 11 },
    { className: "krw", fullName: "wolne za oddanie krwi", code: 12 },
  ];
  let translators = props.translators.map((personItem) => {
    const thisYear = activeDates.filter((dateItem) => {
      const itemDate = new Date(dateItem.date);
      const itemYear = itemDate.getFullYear();
      // console.log(dateItem);
      return (
        parseInt(dateItem.translatorID) === personItem.id &&
        itemYear === date.getFullYear() &&
        dateItem?.type !== 11 && //exclude remote work
        dateItem?.type !== 12 //exclude blood donations
      );
    });
    const sum = thisYear.length;
    const sumUntilToday = thisYear.filter((dateItem) => {
      const itemDate = new Date(dateItem.date);
      return itemDate < today;
    }).length;
    return { ...personItem, sum: sum, sumUntilToday: sumUntilToday };
  });

  translators = [
    "angielski",
    ...translators.filter(
      (item) => item.Languages === "en" || item.Languages === "en,de"
    ),
    // "niemiecki",
    // ...translators.filter((item) => item.Languages === "de"),
    "obsługa klienta",
    ...translators.filter((item) => item.Languages === ""),
  ].filter(
    (person) =>
      parseInt(person.Type) !== 2 &&
      parseInt(person.Type) !== 1 &&
      parseInt(person.Type) !== 4
  );

  const currentUser = translators?.find(
    (i) => parseInt(i.id) === (selectedUser || parseInt(user.id))
  );

  function setHoliday(action, data, multi) {
    if (!multi) {
      switch (action) {
        case "add":
          data = {
            translatorID: data.person,
            employeeID: data.employeeID,
            type: data.dayOffType ? data.dayOffType : 1,
            date: data.day,
          };
          break;
        case "remove":
          data = {
            translatorID: data.person,
            date: data.day,
          };
          break;
        case "update":
          data = {
            translatorID: data.person,
            type: parseInt(data.dayOffType),
            date: data.day,
          };
          break;
      }
    }

    // CHECK IF YOU CAN ADD MORE (FOR STANDARD HOLIDAY OR 'ON DEMAND')
    const aboutToAddNumber = multi
      ? data.filter((d) => [1, 2].includes(d.type)).length - 1
      : 0;

    if (
      (action === "add" || action === "update") &&
      (!aboutToAddNumber ? [1, 2].includes(data.type) : true) &&
      checkMax < 1 + aboutToAddNumber
    ) {
      if (multi) {
        window.alert("Nie masz tyle dostępnego urlopu");
      } else {
        window.alert("Wykorzystałeś już dostępny urlop");
      }
      return;
    }

    fetch(URL + "components/db-holidays.php", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify({
        queryType: action,
        multi: multi,
        data: data,
      }),
    })
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  //console.log(translators);
  return (
    <div className="holidays">
      <div className="main">
        <div className="top-bar">
          <div
            style={{
              display: "flex",
              alignItems: "space-between",
              width: "100%",
              height: "100%",
            }}
          >
            <button
              className="buttonRequests"
              onClick={() => setRequests((prev) => !prev)}
            >
              {requests ? <>{"<"} POWRÓT</> : <>WNIOSKI</>}
            </button>

            <div className="holidayControls">
              <div className="currentMonth">
                <button
                  className="monthLeft"
                  onClick={() => {
                    setDate((prev) => {
                      const newDate = new Date(prev);
                      newDate.setDate(5);
                      newDate.setMonth(newDate.getMonth() - 1);
                      return newDate;
                    });
                  }}
                >
                  {"◀"}
                </button>

                <select
                  value={date.getMonth()}
                  className="holidaySelect"
                  onChange={(e) => {
                    e.preventDefault();
                    setDate((prev) => new Date(prev.setMonth(e.target.value)));
                  }}
                >
                  {months.map((item, index) => (
                    <option value={item.monthID}>{item.monthName}</option>
                  ))}
                </select>
                <button
                  className="monthRight"
                  onClick={() => {
                    setDate((prev) => {
                      const newDate = new Date(prev);
                      newDate.setDate(5);
                      newDate.setMonth(newDate.getMonth() + 1);
                      return newDate;
                    });
                  }}
                >
                  {"▶"}
                </button>
              </div>
              <select
                value={date.getFullYear()}
                className="holidaySelect year"
                onChange={(e) => {
                  e.preventDefault();
                  setDate((prev) => new Date(prev.setFullYear(e.target.value)));
                }}
              >
                <option value={today.getFullYear() - 1}>
                  {today.getFullYear() - 1}
                </option>
                <option value={today.getFullYear()}>
                  {today.getFullYear()}
                </option>
                <option value={today.getFullYear() + 1}>
                  {today.getFullYear() + 1}
                </option>
              </select>
            </div>
            <Link
              to={
                selectedUser
                  ? "../holiday-year-view/" + selectedUser
                  : "../holiday-year-view"
              }
            >
              <div className="daysRemaining">
                {holidaysCount.loading ? (
                  <>
                    zostało
                    <br />
                    <Loading little={true} />
                  </>
                ) : (
                  <>
                    {hCountOff === 1 ? (
                      <>
                        został
                        <br />
                        <span className="number">{hCountOff}</span>{" "}
                        <span
                          title="łącznie z niezatwierdzonymi"
                          className="numberAdd"
                        >
                          {hCountOffSugg}
                        </span>
                      </>
                    ) : ["2", "3", "4"].includes(String(hCount).at(-1)) ? (
                      <>
                        zostały
                        <br />
                        <span className="number">{hCountOff}</span>{" "}
                        <span
                          title="łącznie z niezatwierdzonymi"
                          className="numberAdd"
                        >
                          {hCountOffSugg}
                        </span>
                      </>
                    ) : (
                      <>
                        zostało
                        <br />
                        <span className="number">{hCountOff}</span>{" "}
                        <span
                          title="łącznie z niezatwierdzonymi"
                          className="numberAdd"
                        >
                          {hCountOffSugg}
                        </span>
                      </>
                    )}
                  </>
                )}
              </div>
            </Link>
            <Checkbox
              label="Kalendarz"
              changeFunction={() => setView((prev) => !prev)}
              def={false}
            />
          </div>
        </div>

        <HolidaysContext.Provider
          value={{
            date: date,
            translators: translators,
            translatorsRaw: props.translators,
            user: user,
            setChange: setChange,
            activeDates: activeDates,
            setActiveDates: setActiveDates,
            dayOffTypes: dayOffTypes,
            setContextParams: setContextParams,
            contextParams: contextParams,
            setHoliday: setHoliday,
            today: today,
            actualRemoteWork: actualRemoteWork,
          }}
        >
          {requests ? (
            <HolidayRequests />
          ) : (
            <div className="holidayEditor ">
              {view ? (
                <HolidayCalendar />
              ) : (
                <HolidayTable
                  selectedUser={selectedUser}
                  setSelectedUser={setSelectedUser}
                />
              )}
              <div className="holidayStats">
                <span>
                  {currentUser?.sum ? (
                    currentUser.sum + " dni w " + date.getFullYear() + " r."
                  ) : (
                    <></>
                  )}
                </span>
                <span>
                  {currentUser?.sumUntilToday ? (
                    currentUser.sumUntilToday +
                    " dni do dziś w " +
                    date.getFullYear() +
                    " r."
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div className="holidayKey">
                {dayOffTypes.map((item) => {
                  if (activeTypes.find((type) => parseInt(type) === item.code))
                    return (
                      <span
                        className={item.className}
                        style={{ display: "flex" }}
                      >
                        {item.fullName === "praca zdalna" ? (
                          <img
                            src={homeOfficeIcon}
                            style={{
                              width: "18px",
                              opacity: ".64",
                              paddingRight: "5px",
                              filter:
                                "contrast(.5) sepia(2) saturate(5) hue-rotate(120deg)",
                            }}
                          />
                        ) : (
                          <></>
                        )}
                        {item.fullName}
                      </span>
                    );
                })}
              </div>
            </div>
          )}
        </HolidaysContext.Provider>
      </div>
      <div
        className="contextBack"
        onClick={() =>
          setContextParams((prev) => ({
            ...prev,
            visible: !prev.visible,
          }))
        }
        style={{
          display: contextParams && contextParams.visible ? "block" : "none",
        }}
      ></div>
      <div
        className="contextDialog"
        ref={contextDialog}
        style={
          contextParams
            ? {
                left: contextParams.left,
                top: contextParams.top,
                display: contextParams.visible ? "flex" : "none",
              }
            : {}
        }
      >
        <div className="contextInner">
          <div className="contextHead">
            {contextParams ? contextParams.formattedDate : ""}
          </div>
          {dayOffTypes.map((item) => (
            <button
              className={item.className}
              onClick={() => {
                const multi = contextParams.multipleData ? true : false;
                const multiData = multi
                  ? contextParams.multipleData.map((props) => ({
                      ...props,
                      type: item.code,
                    }))
                  : undefined;

                //console.log(multiData, contextParams);
                const dbData = multi
                  ? multiData
                  : {
                      person: contextParams.person,
                      employeeID: contextParams.new
                        ? user.employeeID
                        : undefined,
                      day: contextParams.day,
                      dayOffType: item.code,
                    };
                setHoliday(contextParams.new ? "add" : "update", dbData, multi);
                setChange((prev) => (prev = prev + 1));
                setContextParams((prev) => ({
                  ...prev,
                  visible: !prev.visible,
                }));
              }}
            >
              {item.fullName}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Holidays;
