import React, { useState, createContext, useEffect } from "react";
import OrderStatistics from "../orders/OrderStatistics";
import OrderContext from "../contexts/order-context";
import Checkbox from "../Checkbox";
import OrdersDay from "../orders/OrdersDay";
import { Routes, Route, Link } from "react-router-dom";
import ripple from "../../images/Ripple-1s-424px.svg";
import { languagesList } from "../global";
import dragon from "../../images/icons8-european-dragon-100.png";
import ordersIcon from "../../images/icons/buttons/orders.svg";
import alarmIcon from "../../images/icons/icons8-alarm-16.png";
import SideMenu from "./SideMenu";
import FullOrderItem from "../orders/FullOrderItem";
import Attendance from "./Attendance";
import { useGeneric } from "../useGeneric";
import Loading from "./Loading";

function CurrentOrders(props) {
  const {
    orders,
    translators,
    loggedIn,
    user,
    darkMode,
    orderParams,
    setOrderParams,
    handleModal,
    orderNotification,
    notificationsActive,
    recentOrders,
  } = props;
  const [modal, setModal] = handleModal;
  const [showContextMenus, setShowContextMenus] = useState(false);
  const [details, setDetails] = useState(true);
  const [mobileVisible, setMobileVisible] = useState(false);
  const [globalFilter, setGlobalFilter] = useState({
    filterBy: "customers_name",
    value: "",
    method: "all",
  });
  const [view, setView] = useState("cards");
  const [menuOpen, setMenuOpen] = useState(false);
  const [updateCustom, setUpdateCustom] = useState(0);

  const loadCustomOrders = useGeneric({
    name: "customOrders",
    reload: 25,
    update: updateCustom,
  });

  const customOrders = !loadCustomOrders.loading
    ? loadCustomOrders.rows
    : undefined;

  // user-friendly filter names
  const filterDictionary = {
    customers_name: "klienci",
    number_order: "nr zlec.",
  };

  return (
    <div
      className={
        "currentOrders interfaceElement" + " list" + (menuOpen && " menuActive")
      }
      onClick={(e) => {
        if (e.target.className !== "docNameWrap") {
          setShowContextMenus(false);
        }
      }}
    >
      <SideMenu setModal={setModal} orders={orders} />
      <div className="main">
        <div
          className="top-bar-toggle"
          onClick={() => setMobileVisible((prev) => !prev)}
        >
          {!mobileVisible ? "▼" : "▲"}
        </div>
        <div className={"top-bar" + (mobileVisible ? " mobileVisible" : "")}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexWrap: "wrap",
            }}
          >
            <div
              className="sideMenuButton"
              onClick={() => setMenuOpen((prev) => !prev)}
            >
              <img src={dragon} className="mainLogo"></img>
            </div>
            <div className="selectMy">
              <select
                value={orderParams.language}
                onChange={(e) => {
                  setOrderParams((prev) => ({
                    ...prev,
                    language: e.target.value,
                  }));
                  window.localStorage.setItem("ordersLanguage", e.target.value);
                }}
              >
                {languagesList
                  .filter((item) => item.code !== "Pol")
                  .map((item, index) => (
                    <option value={item.code} key={index}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>

            <Checkbox
              label="Archiwum"
              changeFunction={() =>
                setOrderParams((prev) => {
                  let type;
                  if (prev.type === "archive") type = "";
                  else type = "archive";
                  return {
                    //count: !type ? prev.count : 100,
                    ...prev,
                    type: type,
                  };
                })
              }
            />
            <Checkbox
              label="Szczegóły"
              changeFunction={() => {
                setDetails((prev) => !prev);
              }}
              def={details}
            />
            <div className="filters">
              <input
                name="orderID"
                type="search"
                maxLength="12"
                placeholder="ID"
                onChange={(e) => {
                  const applyFilter = setTimeout(
                    () =>
                      setGlobalFilter({
                        filterBy: "number_order",
                        value: e.target.value,
                        method: "start",
                      }),
                    250
                  );
                  //setGlobalFilter({ filterBy: "client", value: e.target.value });
                  //if (e.target.value !== globalFilter.value) clearTimeout(applyFilter);
                }}
              ></input>
              <input
                name="client"
                type="search"
                placeholder="Klient"
                onChange={(e) => {
                  const applyFilter = setTimeout(
                    () =>
                      setGlobalFilter({
                        filterBy: "customers_name",
                        value: e.target.value,
                        method: "all",
                      }),
                    250
                  );
                  //setGlobalFilter({ filterBy: "client", value: e.target.value });
                  //if (e.target.value !== globalFilter.value) clearTimeout(applyFilter);
                }}
              ></input>
              <Link to="../view-order">
                <button className="look">🔍</button>
              </Link>
            </div>
          </div>
          <OrderStatistics orders={orders.data} />
          <div className="orderInfoItems">
            <div className="mainInfoItems">
              <div
                className="orderInfoItem"
                onClick={() =>
                  notificationsActive && orderNotification(60000, true)
                }
              >
                {orderParams.type ? "Archiwum" : "Bieżące"}
              </div>
              <div
                className="orderViewType"
                onClick={() =>
                  setView((prev) => (prev === "cards" ? "table" : "cards"))
                }
              >
                {view === "cards" ? "Karty" : "Tabela"}
              </div>
              <Link to="../orders-now" target="_blank">
                <div style={{ padding: "3px" }}>
                  <img src={ordersIcon} width={20} />
                </div>
              </Link>
            </div>
            <div className="newList">
              {orders.data && orders.data.length ? (
                <>
                  <div className="newListHead">Ostatnio przyjęte</div>{" "}
                  {recentOrders.map((item) => (
                    <div
                      className={
                        "newListItem" +
                        (item.minutesAgo < 130 ? " recent" : "") +
                        (item.minutesAgo < 25 ? " superRecent" : "")
                      }
                      title={item.customers_name + ", " + item.pages}
                      style={{
                        borderLeft: `solid ${
                          20 - item.minutesAgo > 5
                            ? 20 - item.minutesAgo + "px"
                            : 5 + "px"
                        } rgba(255,40,40, ${1 - item.minutesAgo / 140})`,
                      }}
                    >
                      <div
                        onClick={() =>
                          setModal((prev) => ({
                            ...prev,
                            show: !prev.show,
                            content: (
                              <FullOrderItem
                                data={item}
                                //abridged={false}
                              />
                            ),
                            type: "Szczegóły zlecenia",
                            width: "wide",
                          }))
                        }
                      >
                        {item.minutesAgo < 720 ? (
                          <div className="createDate">
                            {item.create_date.slice(11, 20).slice(0, -3)}
                          </div>
                        ) : (
                          <></>
                        )}
                        <div>
                          <div className="littleViewOrder">
                            <span className="orderNumber">
                              {item.orderNumber}
                            </span>
                            .
                            <span className="orderMonth">
                              {item.orderMonth}
                            </span>
                            .<span className="orderYear">{item.orderYear}</span>
                            <span className="pages">{item.docPages}</span>
                          </div>
                          <div style={{ fontSize: "80%" }}>
                            {item.orderDate},{item.orderHour}
                          </div>
                        </div>
                        <div className="customerNameInfo">
                          {item.customers_name
                            .split(" ")
                            .splice(0, 3)
                            .join(" ")}
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </div>
            {/*orders.data && orders.data.length ? (
            <>
              <div className="days">
                <Link to="/orders">
                  <div className="day">Wszystko</div>
                </Link>
                <Link to="today">
                  <div className="day">Dziś</div>
                </Link>
                <Link to="tomorrow">
                  <div className="day">Jutro</div>
                </Link>
                <Link to="day-after-tomorrow">
                  <div className="day">Pojutrze</div>
                </Link>
                <Link to="other-days">
                  <div className="day">Inne dni</div>
                </Link>
              </div>
            </>
        ) : null*/}
          </div>
        </div>

        {globalFilter.value ? (
          <>
            <div className="orderFilterInfo">
              FILTR
              <span style={{ fontStyle: "italic" }}>
                ({filterDictionary[globalFilter.filterBy]})
              </span>
              :
              <span
                style={{
                  color: "yellow",
                  fontSize: "150%",
                  textTransform: "uppercase",
                }}
              >
                {" "}
                {globalFilter.value}
              </span>
            </div>
          </>
        ) : (
          <></>
        )}

        {orders.loading && !orders.error ? (
          <Loading text="Pobieranie danych..." />
        ) : !orders?.data?.length ? (
          <div
            style={{ padding: "20px", boxShadow: "2px 2px 5px rgba(0,0,0,.2)" }}
          >
            Brak zleceń
          </div>
        ) : (
          <OrderContext.Provider
            value={{
              globalFilter: globalFilter,
              orders: orders,
              customOrders: customOrders,
              orderParams: orderParams,
              translators: translators,
              details: details,
              setDetails: setDetails,
              modal: modal,
              view: view,
              setModal: setModal,
              loggedIn: loggedIn,
              showContextMenus: showContextMenus,
              setShowContextMenus: setShowContextMenus,
              setUpdateCustom: setUpdateCustom,
            }}
          >
            <div
              className={"ordersList" + (orderParams.type ? " archive" : "")}
            >
              <>
                {!orderParams.type ? (
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <>
                            {["0", "1", "2", "3", "4", "other", "past"].map(
                              (offset, index) => (
                                <OrdersDay key={index} offset={offset} />
                              )
                            )}
                          </>
                        }
                      />
                      <Route path="/today" element={<OrdersDay offset="0" />} />
                      <Route
                        path="/tomorrow"
                        element={<OrdersDay offset="1" />}
                      />
                      <Route
                        path="/day-after-tomorrow"
                        element={<OrdersDay offset="2" />}
                      />
                      <Route
                        path="/other-days"
                        element={<OrdersDay offset="other" />}
                      />
                    </Routes>
                  </>
                ) : (
                  <>
                    <Routes>
                      <Route
                        path="/"
                        element={
                          <>
                            {["0", "-1", "-2", "-3"].map((offset, index) => (
                              <OrdersDay key={index} offset={offset} />
                            ))}
                          </>
                        }
                      />
                      <Route path="/today" element={<OrdersDay offset="0" />} />
                      <Route
                        path="/yesterday"
                        element={<OrdersDay offset="-1" />}
                      />
                    </Routes>
                  </>
                )}
              </>
            </div>
          </OrderContext.Provider>
        )}
      </div>
      <Attendance />
    </div>
  );
}

export default CurrentOrders;
