import { useState } from "react";
import QuickOrderFind from "../tables/instances/newExternals/QuickOrderFind";
import OrderView from "../tables/instances/newExternals/OrderView";

export default function OrderSearch() {
  const [orderID, setOrderID] = useState(false);
  const [query, setQuery] = useState("");

  return (
    <div>
      {!orderID ? (
        <div className="quick-order-outer">
          <h2>Wyszukiwarka zleceń</h2>
          <QuickOrderFind
            query={query}
            setQuery={setQuery}
            orderID={orderID}
            setOrderID={setOrderID}
          />
        </div>
      ) : (
        <>
          <div className="quick-order-back" onClick={() => setOrderID(false)}>
          ◀ Powrót
          </div>
          <OrderView id={orderID} />
        </>
      )}
    </div>
  );
}
